import axiosInstance from './axios';

const allInspections = () => {
  return axiosInstance.get('inspection/all/').then((res) => {
    return res.data.inspections;
  });
};
const allInspectionByUser = (id) => {
  return axiosInstance.get(`inspection/all/user/?id=${id}`).then((res) => {
    return res.data.inspections;
  });
};
const todayInspections = () => {
  return axiosInstance.get('inspection/today/').then((res) => {
    return res.data.inspections;
  });
};
const todayInspectionsByUser = (id) => {
  return axiosInstance.get(`inspection/today/by/user/?id=${id}`).then((res) => {
    return res.data.inspections;
  });
};
const allInspectionsFiltered = ({ department, site, from, to }) => {
  return axiosInstance
    .post(
      `inspection/all/?department=${department}&site=${site}&from=${from}&to=${to}`
    )
    .then((res) => {
      return res.data.inspections;
    });
};
const inspectionShare = (data) => {
  return axiosInstance.post('inspection/share/', { data }).then((res) => {
    return res.data.inspections;
  });
};

const inspection = ({ id, method, ...data }) => {
  if (method === 'get') {
    return axiosInstance.get(`inspection/${id}/`).then((res) => {
      return res.data;
    });
  } else if (method === 'patch') {
    return axiosInstance
      .patch(`inspection/${id}/change/`, { data })
      .then((res) => {
        return res.data;
      });
  } else if (method === 'delete') {
    return axiosInstance.delete(`inspection/${id}/`).then((res) => {
      return res.data;
    });
  } else {
    return null;
  }
};
const getInspectionById = (id) => {
  return axiosInstance.get(`inspection/${id}/`).then((res) => {
    return res.data.data;
  });
};
const departmentReport = ({
  department,
  subDepartment,
  category,
  from,
  to,
}) => {
  return axiosInstance
    .post(
      `department/report/array/?department=${department}&subDepartment=${subDepartment}&category=${category}&from=${from}&to=${to}`
    )
    .then((res) => {
      return res.data.departments;
    });
};

const assetReport = ({ department, subDepartment, category, from, to }) => {
  return axiosInstance
    .post(
      `inspection/report/array/?department=${department}&subDepartment=${subDepartment}&category=${category}&from=${from}&to=${to}`
    )
    .then((res) => {
      return res.data.inspections;
    });
};

const getInspectionByUserId = (id) => {
  return axiosInstance.get(`inspection/user/`).then((res) => {
    return res.data.data;
  });
};

const addPreCheck = ({
  category,
  category_A,
  category_B,
  category_C,
  signature,
  comment,
  compressor,
  mileage,
  total_items,
  score,
  asset,
  user,
  department,
  shift,
  createdAt,
  synced,
  inspectionId,
}) => {
  return axiosInstance
    .post('inspection/add/', {
      category: category,
      category_A: category_A,
      category_B: category_B,
      category_C: category_C,
      signature: signature,
      comment: comment,
      compressor: compressor,
      mileage: mileage,
      total_items: total_items,
      scores: score,
      asset: asset,
      user: user,
      department: department,
      shift: shift,
      createdAt: createdAt,
      synced: synced,
      inspectionId: inspectionId,
    })
    .then((res) => {
      return res.data;
    });
};

const UpdatePrestartSMR = ({ id, mileage }) => {
  return axiosInstance
    .patch(`inspection/${id}/mileage/`, { mileage })
    .then((res) => {
      return res.data;
    });
};

const getInspectionByAUserAndId = (id) => {
  return axiosInstance.get(`/inspection/user/${id}/`).then((res) => {
    return res.data['data'];
  });
};

const InspectionServices = {
  allInspections,
  inspection,
  getInspectionById,
  inspectionShare,
  allInspectionsFiltered,
  todayInspections,
  departmentReport,
  getInspectionByUserId,
  assetReport,
  addPreCheck,
  UpdatePrestartSMR,
  getInspectionByAUserAndId,
  todayInspectionsByUser,
  allInspectionByUser,
};

export default InspectionServices;
