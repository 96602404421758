import React from 'react';
import Home from '../page/home';
import { Login } from '../page/login';
import { Users } from '../page/users';
import { Issue } from '../page/issue';
import { ReportView } from '../components/prestart/report';
import { EditView } from '../components/prestart/edit';
import { UserDetail } from '../components/users/detail';
import { AssetsView } from '../page/asset';
import { Company } from '../page/company';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './protectedRoutes';
import { Service } from '../page/service';
import { VehicleCheckList } from '../components/prestart/forms/vehicleAsset';
import { MachineCheckList } from '../components/prestart/forms/machineAsset';
import { Prestart } from '../page/prestart';
import { AssetDetail } from '../components/asset/detail';
import { TruckCheckList } from '../components/prestart/forms/truckAsset';
import { FolkCheckList } from '../components/prestart/forms/folkAsset';

export const loginUrl = {
  path: 'login',
  element: <Login />,
};

export const UrlsRoute = ({ auth }) => {
  return auth?.map((perm) =>
    [
      {
        id: 1,
        name: 'Home',
        path: '/',
        element: <Home />,
      },
      perm?.includes('view_inspection') && {
        id: 2,
        name: 'Prestart',
        path: '/prestart',
        element: <Prestart />,
      },
      perm?.includes('add_inspection') && {
        id: 3,
        name: 'Vehicle',
        path: '/vehicle-checklist',
        element: <VehicleCheckList />,
      },
      perm?.includes('add_inspection') && {
        id: 4,
        name: 'Machine',
        path: '/machine-checklist',
        element: <MachineCheckList />,
      },
      perm?.includes('view_issue') && {
        id: 5,
        name: 'Issue',
        path: '/issue',
        element: <Issue />,
      },

      perm?.includes('add_user') && {
        id: 6,
        name: 'Users',
        path: '/users',
        element: <Users />,
      },
      perm?.includes('view_inspection') && {
        id: 7,
        name: 'Inspection',
        path: '/inspection/:id/report',
        element: <ReportView />,
      },
      perm?.includes('change_inspection') && {
        id: 8,
        name: 'Inspection',
        path: '/inspection/edit',
        element: <EditView />,
      },
      perm?.includes('view_user') && {
        id: 9,
        name: 'userDetail',
        path: '/user/detail/:id',
        element: <UserDetail />,
      },

      perm?.includes('view_asset') && {
        id: 10,
        name: 'Assets',
        path: '/assets',
        element: <AssetsView />,
      },
      perm?.includes('view_asset') && {
        id: 11,
        name: 'Assets',
        path: '/asset/:id',
        element: <AssetDetail />,
      },
      perm?.includes('add_user') && {
        id: 12,
        name: 'Company',
        path: '/company',
        element: <Company />,
      },
      perm?.includes('view_service') && {
        id: 13,
        name: 'Service',
        path: '/services',
        element: <Service />,
      },

      perm?.includes('add_inspection') && {
        id: 14,
        name: 'Truck',
        path: '/truck-checklist',
        element: <TruckCheckList />,
      },
      perm?.includes('add_inspection') && {
        id: 15,
        name: 'FolkList-TeleHandle-Mobile-crane',
        path: '/folk-list-checklist',
        element: <FolkCheckList />,
      },
    ]?.map((item, index) => (
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route key={index} path={item.path} element={item.element} />
        </Route>
      </Routes>
    ))
  );
};
