import React from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Autocomplete,
  Checkbox,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  Rectangle,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from 'recharts';
import { useQuery, useMutation } from '@tanstack/react-query';
import { BsFiletypePdf } from 'react-icons/bs';
import { useReactToPrint } from 'react-to-print';
import AssetService from '../../../../service/asset';
import DepartmentServices from '../../../../service/department';
import InspectionServices from '../../../../service/inspection';
import CustomPaper from '../../../utilities/customPaper';
import ExportReactCSV from '../../../utilities/csv';
import ExcelExport from '../../../utilities/xlxs';
import TableData from '../../../utilities/table';
import { ReportPrinter } from './reportPrint';
import { usePerms } from '../../../../hooks/usePerms';

export const csvHeaders = [
  {
    label: 'Inspection Id',
    key: 'inspectionId',
  },
  {
    label: 'Asset',
    key: 'asset',
  },
  {
    label: 'Department',
    key: 'department_name',
  },
  {
    label: 'Mileage',
    key: 'mileage',
  },
  {
    label: 'Scores',
    key: 'scores',
  },
  {
    label: 'Total Items',
    key: 'total_items',
  },
  {
    label: 'Shift',
    key: 'shift',
  },
  {
    label: 'Created At',
    key: 'createdAt',
  },
];

const assetCol = [
  {
    field: 'assetId',
    headerName: 'Asset',
    width: 200,
  },

  {
    field: 'target',
    headerName: 'Target',
    width: 180,
  },
  {
    field: 'prestart_count',
    headerName: 'Current Prestart',
    width: 150,
  },
  {
    field: 'target_met_percentage',
    headerName: 'Target Met Percentage',
    width: 200,
  },
  {
    field: 'serviceCycle',
    headerName: 'Service Cycle',
    width: 150,
  },
  {
    field: 'serviceInterval',
    headerName: 'Service Interval',
    width: 160,
  },
];
export const InspectionReports = () => {
  const [selectAll, setSelectAll] = React.useState({
    depart: false,
    category: false,
    subDepartment: false,
  });
  const [itemData, setItemData] = React.useState({
    department: [],
    category: [],
    subDepartment: [],
  });

  const [date, setDate] = React.useState(null);
  const [csv, setCsv] = React.useState([]);
  const [isPrinting, setIsPrinting] = React.useState(false);
  const { handleSubmit, register, reset } = useForm();
  const [dataFilters, setDataFilters] = React.useState([]);
  const [assetData, setAssetData] = React.useState([]);
  const [final, setFinal] = React.useState();
  const entryRole = ['user'];
  const { role, department } = usePerms('', entryRole);
  const componentRef = React.useRef();
  const promiseResolveRef = React.useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'report',

    onBeforeGetContent: () => {
      setIsPrinting(true);
    },
    onAfterPrint: () => {
      setIsPrinting(false);
    },
  });

  const { data: departments, isLoading: isDepartment } = useQuery({
    queryKey: ['department_report'],
    queryFn: async () => {
      const resp = await DepartmentServices.allDepartments();

      if (resp) {
        return [
          {
            createdAt: '',
            id: 0,
            name: 'Select All',
            notes: null,
            updatedAt: null,
          },
          ...resp,
        ];
      }
    },
  });
  const ids = itemData.department.map((item) => item.id) ?? [];
  const allDepartments = role ? department : departments;
  const { data: subDepartment, isLoading: isLoadingSubDepartment } = useQuery({
    queryKey: ['Fetch_sub_department', ids],
    queryFn:
      itemData?.department.length > 0
        ? async () => {
            console.log(ids);
            const resp = await DepartmentServices.subDepartmentByIdDepartments({
              ids,
            });
            if (resp) {
              return [
                {
                  createdAt: '',
                  id: 0,
                  name: 'Select All',
                  notes: null,
                  updatedAt: null,
                },
                ...resp,
              ];
            }
          }
        : [],
  });

  const { data: categories, isLoading: isCategory } = useQuery({
    queryKey: ['category_report'],
    queryFn: async () => {
      const resp = await AssetService.allAssetLabel('category');
      if (resp) {
        return [
          {
            createdAt: '',
            id: 0,
            name: 'Select All',
            notes: null,
            updatedAt: null,
          },
          ...resp,
        ];
      }
    },
  });

  const { mutate: inspectionFilters, isPending: inspectionFilterPending } =
    useMutation({
      mutationFn: InspectionServices.departmentReport,
      onSuccess: (data) => {
        setCsv(data);

        const resp = data.map((e) => ({
          name: e.name,
          total: e.total_target_met_percentage,
          height: 100,
        }));

        const result = Object.values(
          resp.reduce((r, o) => {
            r[o.name] = r[o.name] || {
              name: o.name,
              total: 0,
              height: o.height,
            };
            r[o.name].total = o.total;
            return r;
          }, {})
        );
        const total = data.map((e) => ({
          name: date,
          total: e.total_target_met_percentage,
          height: 100,
        }));

        const finalReport = Object.values(
          total.reduce((r, o) => {
            r[o.name] = r[o.name] || {
              name: o.name,
              total: 0,
              height: o.height,
            };
            r[o.name].total += o.total;
            return r;
          }, {})
        );
        setFinal(finalReport);
        setDataFilters(result);
      },
    });

  const handleClear = () => {
    setItemData({
      department: [],
      category: [],
      subDepartment: [],
    });
    setSelectAll({
      depart: false,
      category: false,
      subDepartment: false,
    });
    setDataFilters([]);
    reset();
  };

  const { mutate: asset, isPending: isAssetPending } = useMutation({
    mutationFn: InspectionServices.assetReport,
    onSuccess: (data) => {
      setAssetData(data);
    },
  });

  const handleSelectionChange = (event, newValue, data, name) => {
    // Check if "Select All" was selected
    const isSelectAllSelected = newValue.some(
      (item) => item.name === 'Select All'
    );

    if (isSelectAllSelected) {
      setSelectAll((prev) => ({
        ...prev,
        depart: name === 'Department' ? true : prev.depart,
        category: name === 'Categories' ? true : prev.category,
        subDepartment: name === 'SubDepartment' ? true : prev.subDepartment,
      }));

      setItemData((prev) => ({
        ...prev,
        department: name === 'Department' ? data : prev.department,
        category: name === 'Categories' ? data : prev.category,
        subDepartment: name === 'SubDepartment' ? data : prev.subDepartment,
      }));

      // Show only "Select All" in the input
      setItemData((prev) => ({
        ...prev,
        department:
          name === 'Department'
            ? [{ id: 0, name: 'Select All' }]
            : prev.department,
        category:
          name === 'Categories'
            ? [{ id: 0, name: 'Select All' }]
            : prev.category,
        subDepartment:
          name === 'SubDepartment'
            ? [{ id: 0, name: 'Select All' }]
            : prev.subDepartment,
      }));
    } else {
      // If "Select All" is deselected, clear all options
      setSelectAll((prev) => ({
        ...prev,
        depart: name === 'Department' ? false : prev.depart,
        category: name === 'Categories' ? false : prev.category,
        subDepartment: name === 'SubDepartment' ? false : prev.subDepartment,
      }));

      setItemData((prev) => ({
        ...prev,
        department:
          name === 'Department'
            ? newValue.filter((item) => item.name !== 'Select All')
            : prev.department,
        category:
          name === 'Categories'
            ? newValue.filter((item) => item.name !== 'Select All')
            : prev.category,
        subDepartment:
          name === 'SubDepartment'
            ? newValue.filter((item) => item.name !== 'Select All')
            : prev.subDepartment,
      }));
    }
  };

  const filterSearch = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction='row' spacing={2} alignContent='center'>
          <Grid item>
            <Typography variant='subtitle2'>Department</Typography>
            <Autocomplete
              fullWidth={true}
              size='small'
              id='combo-box-demo'
              PaperComponent={CustomPaper}
              options={isDepartment ? [] : allDepartments}
              value={itemData.department} // assuming this will now be an array
              loading={isDepartment}
              multiple={true} // enable multiple selections
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) =>
                handleSelectionChange(
                  event,
                  newValue,
                  allDepartments,
                  'Department'
                )
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    checked={
                      selected ||
                      (selectAll.depart && option.id !== 'Select All')
                    }
                    style={{ marginRight: 8 }}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => <TextField {...params} size='small' />}
              sx={{
                width: 'auto',
                minWidth: 200, // Set a minimum width
                maxWidth: '100%', // Let it grow to the full container width if necessary
                flexGrow: 1, // Let it grow based on content
              }}
            />
          </Grid>
          {itemData.department.length > 0 && (
            <Grid item xs={12} sm={3}>
              <Typography variant='subtitle2'>Sub Department</Typography>
              <Autocomplete
                fullWidth={true}
                disabled={itemData.department ? false : true}
                size='small'
                id='combo-box-demo'
                PaperComponent={CustomPaper}
                options={isLoadingSubDepartment ? [] : subDepartment}
                value={itemData.subDepartment}
                loading={isLoadingSubDepartment}
                multiple={true}
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) =>
                  handleSelectionChange(
                    event,
                    newValue,
                    subDepartment,
                    'SubDepartment'
                  )
                }
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      checked={
                        selected ||
                        (selectAll.subDepartment && option.id !== 'Select All')
                      }
                      style={{ marginRight: 8 }}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => <TextField {...params} size='small' />}
                sx={{
                  width: 'auto',
                  minWidth: 200, // Set a minimum width
                  maxWidth: '100%', // Let it grow to the full container width if necessary
                  flexGrow: 1, // Let it grow based on content
                }}
              />
            </Grid>
          )}
          <Grid item>
            <Typography variant='subtitle2'>Asset Category</Typography>
            <Autocomplete
              id='combo-box-demo'
              size='small'
              options={isCategory ? [] : categories}
              value={itemData.category || []}
              fullWidth={true}
              multiple={true}
              loading={isCategory}
              PaperComponent={CustomPaper}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) =>
                handleSelectionChange(event, newValue, categories, 'Categories')
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    checked={
                      selected ||
                      (selectAll.category && option.id !== 'Select All')
                    }
                    style={{ marginRight: 8 }}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} size='small' fullWidth />
              )}
              sx={{
                width: 'auto',
                minWidth: 200, // Set a minimum width
                maxWidth: '100%', // Let it grow to the full container width if necessary
                flexGrow: 1, // Let it grow based on content
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>From</Typography>
            <TextField
              size='small'
              fullWidth
              {...register('from')}
              type='date'
            />
          </Grid>
          <Grid item>
            <Typography variant='subtitle2'>To</Typography>
            <TextField size='small' fullWidth {...register('to')} type='date' />
          </Grid>

          <Grid item xs={12} sm={4} container gap={2}>
            <Button
              sx={{
                textTransform: 'capitalize',
                height: '35px',
                alignSelf: 'end',
                backgroundColor: '#b31313',
                color: 'white',
                borderRadius: '5px',
                '&:hover': {
                  backgroundColor: '#b31313',
                },
              }}
              variant='filled'
              onClick={() => handleClear()}
            >
              Cancel
            </Button>
            <Button
              sx={{
                textTransform: 'capitalize',
                height: '35px',
                alignSelf: 'end',
                backgroundColor: 'primary.light',
                color: 'white',
                borderRadius: '5px',
                '&:hover': {
                  backgroundColor: 'primary.light',
                },
              }}
              variant='filled'
              type='submit'
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };
  const onSubmit = (data, e) => {
    e.preventDefault();
    const departId = itemData.department.map((item) => item.id);
    const subDepartId = itemData.subDepartment.map((item) => item.id);
    const categoryId = itemData.category.map((item) => item.id);
    inspectionFilters({
      department: departId,
      subDepartment: subDepartId,
      category: categoryId,
      from: data.from,
      to: data.to,
    });
    asset({
      department: departId,
      subDepartment: subDepartId,
      category: categoryId,
      from: data.from,
      to: data.to,
    });
  };

  return (
    <Grid container direction='column' gap={6}>
      <Grid
        item
        container
        direction={'column'}
        gap={4}
        sx={{
          padding: '20px 20px',
          boxShadow: '0px 0px 20px 5px #cdcdcd4d',
          backgroundColor: '#ffffff3d',
          backdropFilter: 'blur(20px)',
          borderRadius: '10px',
        }}
      >
        <Grid item container direction={'row'}>
          <Grid item xs={12} sm={8}>
            <Typography variant='h5'>Filter by: </Typography>
          </Grid>
          <Grid item xs={12} sm={4} container justifyContent='flex-end' gap={3}>
            <Button
              variant='outlined'
              size='small'
              onClick={handlePrint}
              startIcon={<BsFiletypePdf />}
              disabled={dataFilters.length > 0 ? false : true}
            >
              PDF
            </Button>
            <ExportReactCSV
              csvData={csv}
              csvHeaders={csvHeaders}
              fileName={`report- ${date}`}
            />
            <ExcelExport data={csv} fileName={`report- ${date}`} />
          </Grid>
        </Grid>
        <Grid item>{filterSearch()}</Grid>
      </Grid>

      {dataFilters.length > 0 ? (
        <Grid item container direction={'column'} gap={7}>
          <Grid item>
            <Typography variant='h4'> Reports</Typography>
          </Grid>
          <Grid item container>
            <Grid item xs={12} sm={10}>
              <ResponsiveContainer height={400}>
                <BarChart
                  data={dataFilters}
                  margin={{
                    top: 20,
                    bottom: 35,
                    right: 50,
                  }}
                  radius={8}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    angle={25}
                    textAnchor='start'
                    interval={0}
                    dy={0}
                    tick={{ fontSize: 10 }}
                  />
                  <YAxis dataKey='height' />
                  {/* <Tooltip /> */}
                  {/* <Legend /> */}
                  <Bar
                    dataKey='total'
                    stackId='a'
                    // label={<Typography>hh</Typography>}
                    fill='#2D76AB'
                    activeBar={<Rectangle fill='pink' stroke='blue' />}
                  >
                    <LabelList
                      dataKey='total'
                      position='top'
                      angle={0}
                      offset={25}
                      fill='red'
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
            <Grid item xs={12} sm={2}>
              <ResponsiveContainer height={400}>
                <BarChart
                  data={final}
                  margin={{
                    top: 20,
                    bottom: 15,
                  }}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis
                    dataKey='name'
                    // angle={10}
                    // textAnchor='end'
                    // interval={0}
                    dy={0}
                  />
                  <YAxis dataKey='height' />
                  {/* <Tooltip /> */}
                  {/* <Legend /> */}
                  <Bar
                    dataKey='total'
                    stackId='a'
                    // label={<Typography>hh</Typography>}
                    fill='#2D76AB'
                    activeBar={<Rectangle fill='pink' stroke='blue' />}
                  >
                    <LabelList
                      dataKey='total'
                      position='top'
                      angle={0}
                      offset={25}
                      fill='red'
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Grid>
            {assetData.length > 0 && (
              <Grid
                item
                xs={12}
                sm={12}
                container
                direction='column'
                gap={2}
                paddingTop={'40px'}
              >
                <Grid item>
                  <Typography variant='h5'>Prestart by Department</Typography>
                </Grid>
                <Grid item>
                  <ResponsiveContainer height={400}>
                    <BarChart
                      data={assetData?.map((item) => ({
                        assetId: item.assetId,
                        target_met_percentage: item.target_met_percentage,
                        total: 100,
                      }))}
                      margin={{
                        top: 20,
                        bottom: 15,
                      }}
                    >
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis
                        dataKey='assetId'
                        angle={10}
                        textAnchor='start'
                        interval={0}
                        dy={0}
                      />
                      <YAxis dataKey='total' />
                      {/* <Tooltip /> */}
                      {/* <Legend /> */}
                      <Bar
                        dataKey='target_met_percentage'
                        stackId='a'
                        // label={<Typography>hh</Typography>}
                        fill='#2D76AB'
                        activeBar={<Rectangle fill='pink' stroke='blue' />}
                      >
                        <LabelList
                          dataKey='target_met_percentage'
                          position='top'
                          angle={0}
                          offset={25}
                          fill='red'
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Grid>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction='column'
              gap={2}
              paddingTop={'40px'}
            >
              <Grid item>
                <Typography variant='h5'>Prestart by Asset</Typography>
              </Grid>
              <Grid item>
                <TableData
                  cols={assetCol}
                  rows={assetData || []}
                  maxWidthMob='84vw'
                  height='400px'
                  isLoading={isAssetPending}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          justifyContent='center'
          alignItems='center'
          padding='30px 0px'
        >
          <Typography variant='body2' color='primary.light'>
            {inspectionFilterPending
              ? 'loading ....'
              : 'No data, Please select'}
          </Typography>
        </Grid>
      )}
      {/* can only hide display in Chrome and firefox */}
      <div style={{ contentVisibility: 'hidden' }}>
        {dataFilters.length > 0 && (
          <ReportPrinter
            ref={componentRef}
            data={dataFilters}
            finalData={final}
            assetData={itemData.department > 0 ? assetData : []}
            isPrinting={isPrinting}
            date={date}
            promiseResolveRef={promiseResolveRef}
          />
        )}
      </div>
    </Grid>
  );
};
