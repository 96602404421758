import React from 'react';
import {
  Grid,
  Paper,
  Typography,
  Button,
  // Autocomplete,
  TextField,
  CircularProgress,
  Stack,
  Chip,
  IconButton,
  Tooltip,
  Autocomplete,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DepartmentServices from '../../../service/department';
// import SpotServices from '../../../service/spot';
import UserServices from '../../../service/user';
// import CustomPaper from '../../utilities/customPaper';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { DialogBox } from '../../utilities/dialog';
import { Content } from '../../../hooks/utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CustomPaper from '../../utilities/customPaper';
import { usePerms } from '../../../hooks/usePerms';

export const Author = ({ user }) => {
  const queryClient = useQueryClient();
  const [allAuth, setAllAuth] = React.useState({
    isEdit: false,
    isAdd: false,
    editId: null,
    name: null,
    value: null,
  });
  const {
    handleSubmit,
    reset,
    register,
    watch,
    formState: { errors },
  } = useForm();
  const [depart, setDepart] = React.useState(null);
  const [group, setGroup] = React.useState(null);
  const [isPassword, setisPassword] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const roles = ['admin', 'superAdmin'];
  const { role } = usePerms('', roles);
  const [onDelete, setOnDelete] = React.useState({
    isDelete: false,
    deletedId: null,
    name: null,
  });
  const { data: departments } = useQuery({
    queryKey: ['departments'],
    queryFn: async () => {
      const data = await DepartmentServices.allDepartments();
      const defaultIndex = user
        ? data.findIndex(({ id }) => id === user.department)
        : null;
      return {
        data: data,
        defaultIndex: defaultIndex,
      };
    },
  });

  const { data: groups } = useQuery({
    queryKey: ['groups_data'],
    queryFn: async () => {
      const data = await UserServices.getAllGroup();
      // console.log(data);
      const defaultIndex = user
        ? data?.findIndex(({ id }) => id === user?.groups[0]['id'])
        : null;

      return { data: data, defaultIndex: defaultIndex };
    },
  });
  const {
    mutate: departmentDetail,
    // isPending: isDepartmentPending,
    // error: departmentError,
  } = useMutation({
    mutationFn: UserServices.addUserDepartment,

    onSuccess: () => {
      setOnDelete({
        isDelete: false,
        name: null,
        deletedId: null,
      });
      queryClient.invalidateQueries({ queryKey: ['user'] });
      queryClient.invalidateQueries({ queryKey: ['Fetch_department_list'] });
    },
  });
  const {
    mutate: removeDepartment,
    isPending: isRemovePending,
    error: removeError,
  } = useMutation({
    mutationFn: UserServices.removeUserDepartment,

    onSuccess: () => {
      setOnDelete({
        isDelete: false,
        name: null,
        deletedId: null,
      });
      queryClient.invalidateQueries({ queryKey: ['user'] });
      queryClient.invalidateQueries({ queryKey: ['Fetch_department_list'] });
    },
  });

  const { mutate, isPending, error } = useMutation({
    mutationFn: UserServices.updateUserPassword,

    onSuccess: () => {
      reset();
      setDepart(null);
      setAllAuth({
        isEdit: false,
        isAdd: false,
        value: null,
        editId: null,
      });

      setisPassword(false);
      setIsLoading2(false);
      setGroup(null);
      queryClient.invalidateQueries({ queryKey: ['user'] });
      queryClient.invalidateQueries({ queryKey: ['groups_data'] });
    },
  });

  const {
    mutate: updateUser,
    isPending: isUpdatePending,
    error: userError,
  } = useMutation({
    mutationFn: UserServices.updateUser,
    onSuccess: () => {
      reset();
      setDepart(null);
      setAllAuth({
        isEdit: false,
        isAdd: false,
        value: null,
        editId: null,
      });

      setisPassword(false);
      setIsLoading2(false);
      setGroup(null);
      queryClient.invalidateQueries({ queryKey: ['user'] });
      queryClient.invalidateQueries({ queryKey: ['groups_data'] });
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isPassword === true) {
      setIsLoading2(true);
      mutate({
        id: data.id,
        password: data.password,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction='column' gap={3}>
        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: '20px 30px',
              backgroundColor: '#2d77ab1a',
              borderRadius: '10px',
            }}
          >
            <Grid container direction='column' gap={4}>
              <Grid item>
                <Grid container direction='row' justifyContent='space-between'>
                  <Grid item>
                    <Typography variant='h6'>Authorization details</Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container direction='column' gap={1}>
                <Typography variant='subtitle2' color='secondary.light'>
                  Department
                </Typography>

                <Stack direction='row' spacing={1} alignItems='center'>
                  {user?.departments?.map((item) => (
                    <Chip
                      label={item.name}
                      onDelete={() => {
                        if (role) {
                          setOnDelete({
                            isDelete: true,
                            deletedId: item.id,
                            name: item.name,
                          });
                        }
                      }}
                    />
                  ))}
                  {role && (
                    <Tooltip title='add a department'>
                      <IconButton
                        onClick={() => {
                          setAllAuth({
                            isEdit: false,
                            isAdd: true,
                            value: null,
                          });
                        }}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Grid>

              <Grid item container direction='column' gap={1}>
                <Typography variant='subtitle2' color='secondary.light'>
                  Role
                </Typography>
                <Stack direction='row' spacing={1}>
                  {user.groups.length > 0
                    ? user.groups?.map((item) => (
                        <Tooltip title='update this role'>
                          <Chip
                            label={item.name}
                            onClick={() => {
                              if (role) {
                                setAllAuth({
                                  isEdit: true,
                                  isAdd: false,
                                  value: null,
                                });
                              }
                            }}
                          />
                        </Tooltip>
                      ))
                    : '-----'}
                </Stack>
                {/* )} */}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <Paper
            elevation={0}
            sx={{
              padding: '20px 30px',
              backgroundColor: '#2d77ab1a',
              borderRadius: '10px',
            }}
          >
            <Grid container direction='column' gap={2}>
              <Grid container direction='row' justifyContent='space-between'>
                <Grid item>
                  <Typography variant='h6'>Password</Typography>
                </Grid>
                <Grid item>
                  <Grid container direction='row' gap={2} alignItems='center'>
                    <IconButton onClick={() => setIsVisible(!isVisible)}>
                      {!isVisible ? (
                        <RemoveRedEyeIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                    {isPassword === true && (
                      <Button
                        sx={{
                          height: 30,
                          border: '1px solid #2D77AB',
                        }}
                        type='submit'
                        variant='outlined'
                        startIcon={
                          isLoading2 ? <CircularProgress /> : <SaveIcon />
                        }
                        disableElevation
                      >
                        Save
                      </Button>
                    )}
                    {role && (
                      <Button
                        sx={{
                          height: 30,
                          border: '1px solid #2D77AB',
                          '&:hover': {
                            backgroundColor: '#032842',
                            color: 'white',
                          },
                        }}
                        disableElevation
                        onClick={() => {
                          if (isPassword === true) {
                            setisPassword(false);
                          } else {
                            reset(user);
                            setisPassword(true);
                          }
                        }}
                        variant='outlined'
                        startIcon={
                          isPassword === true ? (
                            <CloseIcon />
                          ) : (
                            <EditIcon fontSize='10px' />
                          )
                        }
                      >
                        {isPassword === true ? 'Close' : 'Edit'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {isPassword ? (
                  <Grid container direction='column'>
                    <Grid item>
                      <TextField
                        variant='filled'
                        required
                        label='New password'
                        {...register('password')}
                        fullWidth
                        size='small'
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        variant='filled'
                        label='Confirm password'
                        required
                        {...register('confirm_password', {
                          validate: (val) => {
                            if (watch('password') !== val) {
                              return 'Your passwords do no match';
                            }
                          },
                        })}
                        fullWidth
                        size='small'
                      />
                      {errors && (
                        <Typography variant='caption' color='red'>
                          {errors?.confirm_password?.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Typography variant='subtitle1'>
                    {isVisible ? user.plain_password : '*******'}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <DialogBox
        handleClose={() =>
          setOnDelete({
            isDelete: false,
            name: null,
            deletedId: null,
          })
        }
        open={onDelete.isDelete}
        maxWidth='500px'
        title={
          <Typography variant='h6' color='primary'>
            {onDelete.name}
          </Typography>
        }
        content={
          <Content
            title={`Are you sure want to delete  ${onDelete.name} department  `}
            name={'Delete'}
            onCancel={() => {
              setOnDelete({
                isDelete: false,
                name: null,
                deletedId: null,
              });
            }}
            onSubmit={() => {
              const user_id = user.id;
              const department_id = onDelete.deletedId;

              removeDepartment({
                user_id,
                department_id,
              });
            }}
            setIsSmr={() =>
              setOnDelete({
                isDelete: false,
              })
            }
            isPending={isRemovePending}
            error={removeError}
          />
        }
      />
      <DialogBox
        handleClose={() =>
          setAllAuth({
            isEdit: false,
            isAdd: false,
            value: null,
            editId: null,
          })
        }
        open={allAuth.isEdit === true ? allAuth.isEdit : allAuth.isAdd}
        maxWidth='500px'
        title={
          <Typography
            variant='h6'
            color='primary'
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {user.first_name}
          </Typography>
        }
        content={
          <Content
            title={
              <Grid container direction='column' gap={2}>
                <Grid item>
                  <Typography variant='body2'>
                    {allAuth.isAdd
                      ? `Are you sure want to add extra department  ?`
                      : `Are you sure want to update
                  the role ?`}
                  </Typography>
                </Grid>
                <Grid item>
                  {allAuth.isEdit === true ? (
                    <Autocomplete
                      id='combo-box-demo'
                      options={groups.data ?? []}
                      value={
                        user
                          ? group || groups.data[groups.defaultIndex]
                          : group || null
                      }
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        setGroup(newValue);
                      }}
                      PaperComponent={CustomPaper}
                      renderInput={(params) => (
                        <TextField {...params} size='small' />
                      )}
                    />
                  ) : (
                    <Autocomplete
                      id='combo-box-demo'
                      options={departments ? departments.data : []}
                      value={
                        user
                          ? depart ||
                            departments?.data[departments.defaultIndex] ||
                            null
                          : depart || null
                      }
                      getOptionLabel={(option) => option.name}
                      onChange={(event, newValue) => {
                        setDepart(newValue);
                      }}
                      PaperComponent={CustomPaper}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  )}
                </Grid>
              </Grid>
            }
            name={allAuth.isAdd ? 'Add department' : 'update role'}
            onCancel={() => {
              setAllAuth({
                isEdit: false,
                isAdd: false,
                value: null,
                editId: null,
              });
            }}
            onSubmit={() => {
              if (allAuth.isEdit === true) {
                updateUser({
                  id: user.id,
                  method: 'patch',
                  group: group?.id,
                  group_name: group?.name,
                });
              } else {
                departmentDetail({
                  user_id: user.id,
                  department_ids: depart.id,
                });
              }
            }}
            setIsSmr={() =>
              setAllAuth({
                isEdit: false,
                isAdd: false,
                value: null,
                editId: null,
              })
            }
            isPending={isPending || isUpdatePending}
            error={error || userError}
          />
        }
      />
    </form>
  );
};
