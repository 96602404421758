import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const Stepper = ({
  next,
  onclick,
  register,
  setdash,
  message,
  callbackNext,
  mutate,
}) => {
  const [id, setId] = React.useState();
  const [showPassword, setShowPassword] = React.useState(false);
  const [input, setInput] = React.useState({
    idnumber: '',
    password: '',
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (!next) {
        callbackNext(); // Go to the next step
      } else {
        e.preventDefault(); // Prevent form submission behavior
        mutate({
          idnumber: input.idnumber,
          password: input.password,
        });
      }
    }
  };

  if (!next) {
    return (
      <Grid container direction='column' gap={3}>
        <Grid item xs={12} sm={12}>
          <Typography variant='body1' align='center'>
            Welcome again, to start your fleet, kindly login
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} container direction='column' gap={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant='body2'>Email</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              required={true}
              type='text'
              placeholder='enter email'
              fullWidth={true}
              {...register('idnumber', { required: true })}
              onChange={(value) => {
                setId(value.target.value);
                setInput((prev) => ({
                  ...prev,
                  idnumber: value.target.value,
                }));
              }}
              onKeyDown={handleKeyDown}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container direction='column' gap={4}>
        <Grid item container xs={12} sm={12} gap={1}>
          <Button
            startIcon={
              <KeyboardBackspaceOutlinedIcon sx={{ color: 'primary.light' }} />
            }
            onClick={onclick}
            variant='text'
            sx={{
              textTransform: 'capitalize',
            }}
          >
            Log In
          </Button>
        </Grid>
        <Grid item container xs={12} sm={12}>
          <Typography variant='body2'>
            Log in with <b>{id ?? null}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} container direction='column' gap={1}>
          <Grid item xs={12} sm={12}>
            <Typography variant='body2'>Password</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              id='standard-start-adornment'
              required={true}
              type={showPassword ? 'text' : 'password'}
              placeholder='enter password'
              fullWidth={true}
              {...register('password', { required: true })}
              onChange={(value) => {
                setdash();
                setInput((prev) => ({
                  ...prev,
                  password: value.target.value,
                }));
              }}
              error={message ? true : false}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge='end'
                    >
                      {showPassword ? (
                        <VisibilityOffIcon color='black' />
                      ) : (
                        <VisibilityIcon color='black' />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        // onMouseUp={handleMouseUpPassword}
                        edge='end'
                      >
                        {showPassword ? (
                          <VisibilityOffIcon color='black' />
                        ) : (
                          <VisibilityIcon color='black' />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Grid>
          {message && (
            <Grid item xs={12} sm={12}>
              <Typography variant='body2' color='red'>
                {message}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
};

Stepper.propTypes = {
  next: PropTypes.bool,
  onclick: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  setdash: PropTypes.func.isRequired,
  message: PropTypes.string,
};
